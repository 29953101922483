@import "../../assets/style/variables.module.scss";

.sideMenu {
  position: absolute;
  left: 15vh;
  bottom: 0;
  width: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform-origin: bottom left;
  transform: rotate(270deg);
  z-index: 200;

  .wrapper {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 3vh;
    margin: 0.5vh 0;

    button {
      font-size: 1.97vh;
      letter-spacing: 0.47em;
      font-family: $--proxima-regular;
      text-transform: uppercase;
      margin-right: 0.6em;
      cursor: pointer;
      background: transparent;
      color: black;

      &::after {
        content: "●";
        display: inline-block;
        margin-left: 0.6em;
      }

      &:last-child {
        margin-left: 0;

        &::after {
          content: "";
          margin-left: 0;
        }
      }

      &:hover {
        color: $--main-blue !important;
      }
    }

    .selected {
      color: $--main-blue;
    }
  }
}

@media only screen and (max-width: 481px) {
  .sideMenu {
    position: fixed;
    z-index: 600;
    background: rgba($color: #e8e5f9, $alpha: 1);

    padding: 20px 25px 25px 45px;
    height: auto;
    width: 75.5vh;
    box-sizing: border-box;
    left: 40vw;
    // backdrop-filter: blur(50px);
    border: 1px solid #666c71;
    box-shadow: rgba(17, 12, 46, 0.18) 0px 45px 100px 0px;

    .wrapper {
      padding: 0;
      .selected {
        mix-blend-mode: darken;
        color: #1f87f4;
      }
    }
  }
}
