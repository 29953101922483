@import '../../assets/style/variables.module.scss';

.workBar {
  position: fixed;
  left: 0;
  top: calc(45%);
  z-index: 120;
  height: 100vh;

  a {
    text-align: center;
    display: block;
    color: rgb(102, 108, 113);
    font-family: $--bellMtItalic;
    font-style: italic;
    font-size: 30px;
    transform: rotate(-90deg);

    &:hover {
      color: $--main-blue;
    }
  }
}

@media only screen and (max-width: 481px) {
  .workBar {
    display: none;
  }
}
