@import "../../assets/style/variables.module.scss";

.header {
  width: 100vw;
  height: 17vh;
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.5vh;

  .wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .mobileNav {
      font-size: 14px;
      letter-spacing: 0.08em;
      margin: 0 20px;
      font-family: $--proxima-regular;
      font-style: normal;
      color: rgb(102, 108, 113);
      transition: visibility 0s, opacity 1s ease-in-out;
      background: transparent;
    }

    img {
      height: 100%;
    }

    span {
      display: none;
      color: rgb(100, 180, 210);
      height: 100%;
      line-height: 20vh;
      font-size: 15px;
      letter-spacing: 0.08em;
      cursor: pointer;
    }

    &:hover {
      img {
        display: none;
      }
      span {
        display: block;
      }
    }

    .logo {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block !important;
      }
    }
  }
}

@media only screen and (max-width: 481px) {
  .header {
    height: 70px;
    padding-top: 10px;
    background-color: #f4f3ee;
    width: 100vw;
    left: 0;
    transform: unset;
  }

  .aboutHeader {
    background-color: rgb(250, 235, 230);
  }
}
