@import "../../assets/style/variables.module.scss";

.about {
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgb(250, 235, 230);
  padding-top: 20vh;
  padding-bottom: 10vh;
  -webkit-font-smoothing: antialiased;
  -webkit-filter: blur(0.000001px);

  .wrapper {
    width: 80vw;
    margin: auto;
    display: flex;
    gap: 30px;

    .column {
      width: 50%;

      h2 {
        margin-bottom: 1em;
        margin-top: 30px;
        color: rgb(100, 180, 210);
        font-size: 14px;
        letter-spacing: 0.07em;
      }

      p {
        letter-spacing: 0.07em;
        font-size: 14px;
        line-height: 1.25em;
        font-family: $--bellMt;
        // margin-bottom: 0.65em;
        color: rgb(45, 50, 53);
        transform: translateZ(0);
      }

      a {
        color: $--main-blue;
        text-decoration: underline;
        font-weight: 500;
      }
    }
  }
}
.socialMediaWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  a {
    width: 100%;
    height: 45px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid $--main-blue;
    color: $--main-blue;
    font-family: $--proxima-semiBold;
    font-size: 13px;
    background-color: rgb(250, 235, 230);
    margin-bottom: -1px;
    letter-spacing: 0.1em;
  }
}

@media only screen and (max-width: 481px) {
  .about {
    padding-top: 100px;
    height: 100%;
    padding-bottom: 3vh;

    .wrapper {
      flex-direction: column;
      width: 87vw;
      gap: 0px;

      .column {
        width: 100%;
      }
    }
  }
}
