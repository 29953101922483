@import '../../assets/style/variables.module.scss';

.bottomNav {
  position: absolute;
  bottom: 2vh;
  width: 100%;
  z-index: 100;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 78%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    button {
      background: transparent;
      height: 12px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      span {
        display: block;
        background: none;
        content: counter(dot-no);
        -ms-transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
        position: relative;
        text-align: left;
        width: auto;
        font-size: 16px;
        line-height: 12px;
        text-indent: 13px;
        color: rgb(100, 180, 210);
      }

      .line {
        width: 1px;
        height: 100%;
        background: rgb(102, 108, 113);
        transition: all 0.5s ease;
      }

      .selected {
        width: 3px !important;
        background: $--main-blue;
      }
    }
  }
}

@media only screen and (max-width: 481px) {
  .bottomNav {
    display: none !important;
  }
}
