@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap");

@font-face {
  font-family: "Proxima-Regular"; /*a name to be used later*/
  src: url("../fonts/proxima-nova-webfont.woff2"); /*URL to font*/
}

@font-face {
  font-family: "Proxima-SemiBold"; /*a name to be used later*/
  src: url("../fonts/proxima-nova-semibold-webfont.woff2"); /*URL to font*/
}

@font-face {
  font-family: "Bell_MT_Italic"; /*a name to be used later*/
  src: url("../fonts/Bell_MT_Italic.woff2"); /*URL to font*/
}

@font-face {
  font-family: "Bell_MT"; /*a name to be used later*/
  src: url("../fonts/Bell_MT.woff2"); /*URL to font*/
}

// CUSTOM FONT
$--proxima-regular: "Proxima-Regular";
$--proxima-semiBold: "Proxima-SemiBold";
$--garamond: "EB Garamond", serif;
$--bellMtItalic: "Bell_MT_Italic", serif;
$--bellMt: "Bell_MT", serif;

$--main-blue: rgb(100, 180, 210);

body {
  font-family: $--proxima-regular;
  background-color: #f4f3ee;
}
