@import '../../assets/style/variables.module.scss';

.workCard {
  width: 100vw;
  margin: 20vh auto;
  position: relative;

  .imageCard {
    width: 100vw;
    height: calc(81.5vh - 75px);
    display: flex !important;
    align-items: center;
    justify-content: center;
    img,
    video {
      height: 100%;
      width: auto;
      background-image: url(http://blog.teamtreehouse.com/wp-content/uploads/2015/05/loading.gif);
      background-repeat: no-repeat;
      background-position: center 25vh;
      max-width: 85%;
      object-fit: cover;
      object-position: center;
    }
  }

  .infoCardWrapper {
    position: absolute;
    left: calc(100vw - 320.5px);
    // top: calc(50% + 45px);
    top: calc(50%);
    bottom: 0;
    transition: left 700ms;
    // transform: translateY(-50%);
    z-index: 500;
    display: flex;
    align-items: flex-start;
  }

  .infoNavWrapper {
    display: flex;
    flex-direction: column;
    max-height: calc(15px * 11);
    flex-wrap: wrap;
    margin-left: 10px;
    gap: 10px;
    div {
      width: 15px;
      height: 15px;
      border: 1px solid rgb(102, 108, 113);
      border-radius: 50%;
      background-color: rgb(244, 243, 238);
      cursor: pointer;

      font-size: 10px;
      text-align: center;
      line-height: 16px;
      display: block;
      color: rgb(102, 108, 113);
    }

    .selected {
      background-color: $--main-blue;
    }
  }

  .infoCard {
    width: 155px;
    background-color: rgb(250, 235, 230);
    border: 1px solid rgb(102, 108, 113);
    padding: 8px;

    h3 {
      font-family: $--proxima-regular;
      font-size: 12px;
      color: rgb(45, 50, 53);
      display: block;
      letter-spacing: 0.08em;
      line-height: 15px;
      font-weight: 400;
      text-transform: uppercase;
    }

    h4 {
      font-family: $--garamond;
      line-height: 15px;
      text-transform: lowercase;
      font-style: italic;
      font-size: 13px;
      letter-spacing: 0.08em;
      display: inherit;
      color: rgb(45, 50, 53);
      margin-top: 3px;
    }

    p {
      color: rgb(102, 108, 113);
      font-size: 9px;
      line-height: 14px;
      font-family: $--proxima-regular;
      text-transform: uppercase;
      letter-spacing: 0.08em;
    }

    a {
      color: rgb(45, 50, 53);
      text-decoration: underline;
      font-family: $--proxima-regular;
      font-size: 9px;
      text-transform: uppercase;
    }

    .emptySpace {
      height: 10px;
    }
  }
}

@media only screen and (max-width: 481px) {
  .workCard {
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: unset;
    margin-bottom: 60px;

    .imageCard {
      width: 100%;
      height: auto;

      img,
      video {
        width: 100%;
      }
    }

    .infoCardWrapper {
      position: static;
      margin-top: 10px;

      .infoNavWrapper {
        display: none;
      }
    }
  }
}
